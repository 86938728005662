<template>
  <div class="flex flex-row">
    <div class="flex flex-col flex-1">
      <h1 class="text-2xl font-semibold text-gray-900">
        Home
      </h1>
      <TextBelgiumTime />
    </div>
    <div class="flex justify-end items-center">
      <ButtonLogout />
    </div>
  </div>

  <PagePlanning hide-time v-if="store.getters.hasChauffeurOrOnderaannemerPermission" hide-filters only-today />
  <!-- <div v-if="store.getters.hasChauffeurOrOnderaannemerPermission" class="grid gap-x-4 gap-y-8 w-full max-w-2xl pt-4">
    <div class="card w-full flex flex-col gap-4">
      <router-link class="btn info flex flex-row justify-between items-center p-3" to="/chauffeur/planning">
        <span><i class="fas fa-list inline-block pr-2 -mt-1 text-lg"></i> Planning raadplegen</span>
        <i class="fas fa-arrow-right"></i>
      </router-link>
      <router-link class="btn info flex flex-row justify-between items-center p-3" to="/chauffeur/afwezigheid">
        <span><i class="fas fa-calendar inline-block pr-2 -mt-1 text-lg"></i> Aanvraag afwezigheid</span>
        <i class="fas fa-arrow-right"></i>
      </router-link>
      <router-link class="btn info flex flex-row justify-between items-center p-3" to="/chauffeur/onderhoud">
        <span><i class="fas fa-wrench inline-block pr-2 -mt-1 text-lg"></i> Aanvraag onderhoud</span>
        <i class="fas fa-arrow-right"></i>
      </router-link>
    </div>

    <div class="card w-full flex flex-col gap-4">
      <router-link class="btn info flex flex-row justify-between items-center p-3" to="/chauffeur/procedures">
        <span><i class="fas fa-info-circle inline-block pr-2 -mt-1 text-lg"></i> Procedures raadplegen</span>
        <i class="fas fa-arrow-right"></i>
      </router-link>
      <router-link class="btn info flex flex-row justify-between items-center p-3" to="/sign/arbeidsregelement">
        <span><i class="fas fa-book inline-block pr-2 -mt-1 text-lg"></i> Arbeidsreglement raadplegen</span>
        <i class="fas fa-arrow-right"></i>
      </router-link>
    </div>

    <div class="card w-full flex flex-col gap-4">
      <router-link class="btn info flex flex-row justify-between items-center p-3" to="/chauffeur/bestuurderspas">
        <span><i class="fas fa-credit-card inline-block pr-2 -mt-1 text-lg"></i> Bestuurderspas raadplegen</span>
        <i class="fas fa-arrow-right"></i>
      </router-link>
      <router-link class="btn info flex flex-row justify-between items-center p-3" to="/chauffeur/rijbewijs">
        <span><i class="fas fa-credit-card inline-block pr-2 -mt-1 text-lg"></i> Rijbewijs raadplegen</span>
        <i class="fas fa-arrow-right"></i>
      </router-link>
    </div>

    <div class="card w-full flex flex-col gap-4">
      <router-link class="btn warn flex flex-row justify-center items-center gap-2 p-3" to="/chauffeur/afwezigheid">
        Probleem met app melden
      </router-link>
    </div>
  </div> -->
  <div v-else-if="store.getters.hasBoekhouderPermission" class="flex flex-col gap-4 my-4 items-start">
    <router-link class="btn info w-64 flex flex-row justify-between p-3" to="/facturatie/facturen/overzicht">Facturen raadplegen <i class="fas fa-arrow-right"></i></router-link>
    <router-link class="btn info w-64 flex flex-row justify-between p-3" to="/facturatie/creditnotas/overzicht">Creditnota's raadplegen <i class="fas fa-arrow-right"></i></router-link>
  </div>
  <div v-else-if="store.getters.hasKlantPermission">Klant</div>
  <div v-else></div>

</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

const PagePlanning = defineAsyncComponent(() => import('@/pages/chauffeur/Planning.vue'))
const ButtonLogout = defineAsyncComponent(() => import('@/components/UI/Button/Logout.vue'))
const TextBelgiumTime = defineAsyncComponent(() => import('@/components/UI/Text/BelgiumTime.vue'))

const store = useStore()
</script>
